import { Component, Inject, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { WorkService } from '../services/work.service';
import { Navigation } from '../../shared/models/type-list';

@Component({
  selector: 'ergo-stats-dialog',
  templateUrl: './ergo-stats-dialog.component.html',
  styleUrls: ['./ergo-stats-dialog.component.scss']
})
export class ErgoStatsDialog implements OnInit { 
  numGreen: number;
  numYellow: number;
  numRed: number;
  numGray: number;

  ergoForm: FormGroup;  // FormGroup for ergo score updates
  formSubscription: Subscription;
  formIsDirty: boolean = false;
  formIsInvalid: boolean = false;
  currentNav: Navigation = {
    plant_name: '',
    line: '',
    subline: '',
    team_id: 0,
    version_id: 0
  };

  initialFormValues: any;

  @Output() onFormStatusUpdate = new EventEmitter<{ isDirty: boolean, isValid: boolean }>();

  constructor(
    private fb: FormBuilder,
    private workService: WorkService,
    public dialogRef: MatDialogRef<ErgoStatsDialog>,
    @Inject(MAT_DIALOG_DATA) public teaminfo: any
  ) {
    let ergoStats = teaminfo.ergo_stats;
    this.numGreen = ergoStats.green;
    this.numYellow = ergoStats.yellow;
    this.numRed = ergoStats.red;
    this.numGray = ergoStats.gray;
  }

  ngOnInit() {
    this.workService.getWorkFormatted(this.teaminfo.team_id, this.teaminfo.version_id).subscribe(
      out => {
        const combinedWorkstations = [
          ...out.Body.odd_workstations,
          ...out.Body.even_workstations
        ];
        this.initForm(combinedWorkstations);
  
        // Subscribe to form value changes
        this.ergoForm.valueChanges.subscribe(() => {
          this.checkIfFormIsDirty(); // Check if the form is dirty
        });
      },
      error => {
        console.error('Error fetching work formatted data:', error);
      }
    );
  }

  initForm(pitches: any[]) {
    this.ergoForm = this.fb.group({
      ergoScores: this.fb.array(
        pitches.map(pitch => this.fb.group({
          ergo_score: [pitch.ergo_score, [Validators.required]],
          pitch_name: [pitch.pitch_name],
          pitch_id: [pitch.pitch_id],
          pitch_config_id: [pitch.pitch_config_id],
          subassembly_id: [pitch.subassembly_id],
          ergo_comments: [pitch.ergo_comments]
        }))
      )
    });
  
    // Store initial form values
    this.initialFormValues = this.ergoForm.value;
    console.log("Initial form values:", this.initialFormValues);
  }

  get ergoScores() {
    return this.ergoForm.get('ergoScores') as FormArray;
  }

  onSubmit() {
    if (this.ergoForm.valid) {
      const updatedErgoScores = this.ergoForm.value.ergoScores.map(score => ({
        pitch_config_id: score.pitch_config_id,
        ergo_score: score.ergo_score,
        ergo_comments: score.ergo_comments
      }));

      console.log('Updated Ergo Scores:', updatedErgoScores);
      this.workService.saveErgoScores(updatedErgoScores).subscribe(
        out => {
          this.dialogRef.close(true);
        }
      );
    }
  }

  onNoClick(): void {
    console.log("Close");
    this.dialogRef.close(false);
  }

  navToSSRS(): void {
    console.log("display report");
    console.log("value of url ", this.teaminfo.urlvalue);
    let urlToReport = this.teaminfo.urlvalue;
    window.open(urlToReport, '_blank');
  }

  getValidationErrors() {
    this.ergoForm.controls.ergoScores['controls'].forEach((group: FormGroup) => {
      Object.keys(group.controls).forEach(controlName => {
        const control = group.get(controlName);
        if (control && control.invalid) {
          console.log(`${controlName} errors:`, control.errors);
        }
      });
    });
  }

  checkIfFormIsDirty() {
    const currentValues = this.ergoForm.get('ergoScores') as FormArray;
    const initialValues = this.initialFormValues.ergoScores;
  
    // Determine if any value has changed
    const isDirty = currentValues.controls.some((control, index) => {
      const initialValue = initialValues[index];
      return JSON.stringify(control.value) !== JSON.stringify(initialValue);
    });
  
    this.formIsDirty = isDirty;
  
    // Emit the form status
    this.onFormStatusUpdate.emit({
      isDirty: this.formIsDirty,
      isValid: this.ergoForm.valid
    });
  }
  
}
