<div class="dialog-container" style="width: auto;">
  <h3 mat-dialog-title class="title">
    Team Ergo Stats
    <button color="primary" mat-raised-button id="Report" class="mat-raised-button" type="button" (click)="navToSSRS()">
      Ergo Summary Report
    </button>
  </h3>

  <p class="title">Total number of pitches in each ergo category.</p>

  <div class="ergo-column">
    <div class="ergo-row">
      <span class="textgreen-ergo">Ergo Score <= 19</span>  
      <span class="score-ergo">{{numGreen}}</span>
    </div>

    <div class="ergo-row">
      <span class="textyellow-ergo">Ergo Score >= 20 and <= 29</span>
      <span class="score-ergo">{{numYellow}}</span>
    </div>

    <div class="ergo-row">
      <span class="textred-ergo">Ergo Score > 29</span>
      <span class="score-ergo">{{numRed}}</span>
    </div>

    <div class="ergo-row">
      <span class="textgray-ergo">No Ergo Score</span>
      <span class="score-ergo">{{numGray}}</span>
    </div>
  </div>

  <p class="title">Modify Ergo Scores for each pitch.</p>

  <div class="ergo-stats-form">
    <form [formGroup]="ergoForm" (ngSubmit)="onSubmit()" *ngIf="ergoForm">
      <div class="ergo-header">
        <label>Pitch</label>
        <label>Score</label>
        <label>Comment</label>
      </div>

      <div class="ergo-rows" formArrayName="ergoScores">
        <div *ngFor="let ergoGroup of ergoScores.controls; let i = index" [formGroupName]="i" class="ergo-row">
          <label *ngIf="!ergoGroup.get('subassembly_id').value">
            {{ ergoGroup.get('pitch_id').value }} {{ ergoGroup.get('pitch_name').value }}
          </label>
          <label *ngIf="ergoGroup.get('subassembly_id').value">
            {{ ergoGroup.get('pitch_id').value }} ({{ ergoGroup.get('subassembly_id').value }}) {{ ergoGroup.get('pitch_name').value }}
          </label>
          <input formControlName="ergo_score" type="number" class="input-field"
          [ngClass]="{ 'hide-negative': ergoGroup.get('ergo_score').value < 0 }"/>
          <input formControlName="ergo_comments" type="text" class="input-field-comment"/>
        </div>
      </div>
    </form>
  </div>

  <!-- Buttons Container -->
  <div class="buttons-container">
    <button color="primary" mat-raised-button type="button" (click)="onSubmit()" [disabled]="!formIsDirty || ergoForm.invalid">Save</button>
    <button color="primary" mat-raised-button id="Close" type="button" (click)="onNoClick()">Close</button>
  </div>
</div>
