import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { PlantType, VariantType, CurrentTeamType, PitchType, Flag,
         Version, LineTeam, SelectTeam } from '../../shared/models/type-list';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends AbstractRestService {

  public line_team$ = new BehaviorSubject<LineTeam>(null)
  public version$ = new BehaviorSubject<Version>(null)
  public team_id$ = new BehaviorSubject<number>(null)

  private currentTeamData: CurrentTeamType
  postInit(): void{}

 
  plantList(): Observable<GenericLambdaReturn<PlantType[]>>{
    console.log(' using plantType  ')
    return this.get({
      ApiResource: "/config",
      repeatCall: false
    });
  }

  getTeams(plant_name: string, line_name: string, subline_name: string): Observable<GenericLambdaReturn<LineTeam[]>>{

    let params:any = {}

    params.PlantName = plant_name

    if (line_name != '') {
      params.Line = line_name
    }

    params.Subline = subline_name

    return this.get({
      ApiResource: "/team",
      data: params,
      repeatCall: false
    });
  }

  createTeam(plant_name:string, line:string, team_name:string, subline?:string, timeUnit?:string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};
    params.PlantName = plant_name;
    params.Line = line;
    if (subline !== undefined)
      params.Subline = subline; 
    params.TeamName = team_name;
    params.DeletedDate = 'false';
    if (timeUnit !== undefined)
      params.TaktTimeUnit = timeUnit; 

    return this.post({
      ApiResource: "/team",
      data: {
        data: params
      }
    });
  }

  updateTeam(team_id:number, plant_name:string, line:string, team_name:string, deleted_date?:string, subline?:string, timeUnit?:string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    params.TeamID = team_id;
    params.PlantName = plant_name;
    params.Line = line;
    if (subline !== undefined)
      params.Subline = subline; 
    params.TeamName = team_name;
    params.DeletedDate = deleted_date;
    if (timeUnit !== undefined)
      params.TaktTimeUnit = timeUnit; 

    return this.post({
      ApiResource: "/team",
      data: {
        data: params
      }
    });
  }


  getPitches(team_id: number, version_id: number): Observable<GenericLambdaReturn<PitchType[]>>{
    console.log(' Using pitch type ')

    let params: any = {}

    params.TeamID = team_id
    params.VersionID = version_id

    return this.get({
      ApiResource: "/pitch",
      data: params,
      repeatCall: false
    });
  }

  createPitch(plant_name:string,team_id:number,version_id:number,pitch_id:string,pitch_type:string,subassembly_id:string,takt_time:number,pitch_status:string,variantIDs:number[],pitchName: string, service_attribute: string, service_color: string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    params.PlantName = plant_name;
    params.PitchID = pitch_id;
    params.TeamID = team_id; 
    params.VersionID = version_id;
    params.PitchType = pitch_type;
    params.SubassemblyID = subassembly_id;
    params.TaktTime = takt_time;
    params.PitchStatus = pitch_status;
    params.VariantIDs = variantIDs;
    params.PreviouspitchID = pitch_id;
    params.PrevioussubassemblyId = subassembly_id;
    params.PitchName = pitchName;
    params.ServiceAttribute = service_attribute;
    params.ServiceColor = service_color;

    return this.post({
      ApiResource: "/pitch",
      data: {
        data: params
      }
    });
  }

  updatePitch(plant_name:string, team_id:number, version_id:number, pitch_config_id:number, pitch_id:string, pitch_type:string, subassembly_id:string, takt_time:number, pitch_status:string, variantIDs:number[], pitchName: string, ergoScore: number, ergoComments : string, service_attribute?:string, service_color?: string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};
    
    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.PitchConfigID = pitch_config_id;
    params.PitchID = pitch_id;
    params.PitchType = pitch_type;
    params.SubassemblyID = subassembly_id;
    params.TaktTime = takt_time;
    params.PitchStatus = pitch_status;
    params.VariantIDs = variantIDs;
    params.PitchName = pitchName;
    params.ErgoScore = ergoScore;
    params.ErgoComments = ergoComments;
    if (service_attribute != undefined)
      params.ServiceAttribute = service_attribute;
    if (service_color != undefined)
      params.ServiceColor = service_color;

    return this.post({
      ApiResource: "/pitch",
      data: {
        data: params
      }
    });
  }

  deletePitch(pitch_config_id:number,plant_name:string,team_id:number,version_id:number,pitch_id:string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    params.PitchConfigID = pitch_config_id;
    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.PitchID = pitch_id;

    return this.delete({
      ApiResource: "/pitch",
      data: params
    });
  }

  deletePitchVariant(pitch_config_id:number,variant_id:number,plant_name:string,team_id:number,version_id:number,pitch_id:string,variant_name:string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    params.PitchConfigID = pitch_config_id;
    params.VariantID = variant_id;
    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.PitchID = pitch_id;
    params.VariantName = variant_name;

    return this.post({
      ApiResource: "/pitchandvariant",
      data: {
        data: params
      }
    });
  }

  createVariant(team_id:number , variant_name:string , version_id:number,plant_name:string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    console.debug(team_id)
    params.PlantName = plant_name;
    params.TeamID = team_id; 
    params.VariantName = variant_name;
    params.VersionID = version_id;
    params.Action = 'Insert';
    params.VariantID = '';
    return this.post({
      ApiResource: "/variant",
      data: {
        data: params
      }
    });
  }

  getVariant(team_id: number):Observable<GenericLambdaReturn<VariantType[]>>{

    let params: any = {}

    params.TeamID = team_id

    return this.get({
      ApiResource: "/variant",
      data: params,
      repeatCall: false
    });
  }

  getFlags():Observable<GenericLambdaReturn<Flag[]>>{

    let params: any = {}

    return this.get({
      ApiResource: "/flag",
      data: params,
      repeatCall: false
    });
  }

  updateVariant(team_id:number,variant_name:string,variant_id:number, version_id:number,plant_name:string):Observable<GenericLambdaReturn<any>>{

    let params: any = {};

    console.debug(team_id)
    params.PlantName = plant_name;
    params.TeamID = team_id; 
    params.VariantName = variant_name;
    params.Action = 'Update';
    params.VariantID = variant_id;

    return this.post({
      ApiResource: "/variant",
      data: {
        data: params
      }
    });
  }

  getSelectedTeamData(): CurrentTeamType{
    return this.currentTeamData
  }

  createPitchFlagConfig(pitch_config_id: number, flags: string[], plant_name:string, team_id: number, version_id: number, pitch_id: string){
    let params: any = {};

    params.PlantName = plant_name;
    params.PitchConfigID = pitch_config_id; 
    params.Flags = flags;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.PitchID = pitch_id;

    return this.post({
      ApiResource: "/pitchflag",
      data: {
        data: params
      }
    });
  }

  deletePitchFlagConfig(pitch_config_id: number, flag: string, plant_name:string, team_id: number, version_id: number, pitch_id: string){
    let params: any = {};
    params.PlantName = plant_name;
    params.PitchConfigID = pitch_config_id; 
    params.Flag = flag;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.PitchID = pitch_id;

    return this.delete({
      ApiResource: "/pitchflag",
      data: params
    })
  }
  
  getVersions(team_id: number): Observable<GenericLambdaReturn<Version[]>>{

    let params:any = {}

    params.TeamID = team_id

    return this.get({
      ApiResource: "/version",
      data: params,
      repeatCall: false
    });
  }

  duplicateVersion(team_id: number, version_id: number, plant_name:string, version_name: string, takt_time: number, demand: number, duplicate_version: string){
    let params: any = {};
    console.log(team_id)
    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.VersionName = version_name;
    params.TaktTime = takt_time;
    params.Demand = demand;
    params.DupVersionName = duplicate_version;

    return this.post({
      ApiResource: "/duplicate",
      data: {
        data: params
      }
    });
  }

  createVersion(team_id:number, team_name: string, site_config_id: number, plant_name:string, version_name: string, num_pitches: number, two_sided: string, takt_time: number, active: string, demand: number, base_variant_id: number, takt_time_unit: string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};
    
    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.TeamName = team_name;
    params.SiteConfigID = site_config_id;
    params.VersionName = version_name;
    params.NumPitches = num_pitches;
    params.TwoSided = two_sided;
    params.TaktTime = takt_time;
    params.Active = active;
    params.Demand = demand;
    params.BaseVariantID = base_variant_id;
    params.TaktTimeUnit = takt_time_unit;

    return this.post({
      ApiResource: "/version",
      data: {
        data: params
      }
    });
  }

  deleteVersion( plant_name:string,team_id:number,version_id:number, version_name: string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};
    console.log('inside delete service ', plant_name , team_id,version_id, version_name)
    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.VersionName = version_name;
  
    return this.delete({
      ApiResource: "/version",
      data: params
    });
  }

  undeleteVersion( plant_name:string,team_id:number,version_id:number, version_name: string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};
    console.log('inside undelete service ', plant_name , team_id,version_id, version_name)
    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.VersionName = version_name;

    return this.post({
      ApiResource: "/undelversion",
      data: {
        data: params
      }
    });
    
  }

  updateVersion(team_id:number, plant_name:string, version_name: string, num_pitches: number, two_sided: string, takt_time: number, active: string, version_id: number, team_name: string, site_config_id: number, demand: number, base_variant_id: number, takt_time_unit: string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.TeamName = team_name;
    params.SiteConfigID = site_config_id;
    params.VersionID = version_id;
    params.VersionName = version_name;
    params.NumPitches = num_pitches;
    params.TwoSided = two_sided;
    params.TaktTime = takt_time;
    params.Active = active;
    params.Demand = demand;
    params.BaseVariantID = base_variant_id;
    params.TaktTimeUnit = takt_time_unit;

    return this.post({
      ApiResource: "/version",
      data: {
        data: params
      }
    });
  }

  deleteVariant(plant_name: string, team_id: number, variant_id: number, variant_name:string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.VariantID = variant_id;
    params.VariantName = variant_name;

    return this.delete({
      ApiResource: "/variant",
      data: params
    });
  }

  getTeamsWithVersions(plant_name: string, line_name: string, subline_name: string, include_version?: string): Observable<GenericLambdaReturn<SelectTeam[]>>{

    let params:any = {}

    params.PlantName = plant_name

    if (line_name != '') {
      params.Line = line_name
    }

    if (subline_name != '') {
      params.Subline = subline_name
    }

    if (include_version != undefined && include_version == 'true'){
      params.IncludeVersion = include_version
    }

    return this.get({
      ApiResource: "/team",
      data: params,
      repeatCall: false
    });
  }

  updateStandardWork(plant_name: string, team_id: number, version_id: number):Observable<GenericLambdaReturn<any>> {
    let params: any = {};

    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.VersionID = version_id;


    return this.post({
      ApiResource: "/standardwork",
      data: {
        data: params
      }
    });
  }

  migrateTeam(plant_name: string, line: string, subline: string, teamID: number){
    let params: any = {}

    params.PlantName = plant_name
    if (line != '') params.Line = line
    if (subline != '') params.Subline = subline
    if (teamID != 0) params.TeamID = teamID

    return this.post({
      ApiResource: "/team/migrate",
      data: {data: params},
      repeatCall: false
    });
  }
}